import React from "react"
import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"
import wc from "../../images/projetos/wc.svg"
import quartos from "../../images/projetos/quartos.svg"
import garagem from "../../images/projetos/garagem.svg"
import area from "../../images/projetos/area.svg"

const Details = ({data}) => {
  
  return(
    <StyledDetails>
      <div className="details-content">
        {data.iconInfo.area && <div className="content-row">
          <p className="icons"><img src={area} alt="Área"/></p>
          <div className="icons-detes"><p className={font.aGM}>{data.iconInfo.area}</p></div>
        </div>}
        {data.iconInfo.garagem && <div className="content-row">
          <p className="icons"><img src={garagem} alt="Garagem"/></p>
          <div className="icons-detes"><p className={font.aGM}>{data.iconInfo.garagem}</p></div>
        </div>}
        {data.iconInfo.wc && <div className="content-row">
          <p className="icons"><img src={wc} alt="WC"/></p>
          <div className="icons-detes"><p className={font.aGM}>{data.iconInfo.wc}</p></div>
        </div>}
        {data.iconInfo.quartos && <div className="content-row">
          <p className="icons"><img src={quartos} alt="Quartos"/></p>
          <div className="icons-detes"><p className={font.aGM}>{data.iconInfo.quartos}</p></div>
        </div>}
      </div>
      <div className="details-text">
        <p className={font.aGR}><span style={{fontWeight: data.text.length>1 && "bold"}}>{data.text[0]}</span>{data.text.length>1 && <span><br/>{data.text[1]}</span>}</p>
      </div>
    </StyledDetails>
  )
}

export default Details

const StyledDetails = styled.div`
  position: relative;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6vw auto;
  .details-content{
    width: 30%;
    .content-row{
      display: flex;
      align-items: center;
      color: #fa4616;
      margin: 1em 0;
      .icons{
        width: 4em;
        display: flex;
        justify-content: center;
        img{
          height: 2em;
        }
      }
    }
    .gray{color: #e8e8e8;}
  }
  .details-text{width: 70%;}
  @media only screen and (max-width: 1400px){
    width: 60%;
  }
  @media only screen and (max-width: 1200px){
    width: 70%;
  }
  @media only screen and (max-width: 1000px){
    width: 80%;
  }
  @media only screen and (max-width: 900px){
    width: 90%;
  }
  @media only screen and (max-width: 768px){
    flex-direction: column-reverse;
    position: relative;
    width: 100%;
    margin: 12vw auto 6vw;
    .details-content{
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 27%;
      margin: 4vw;
      .content-row{
        margin: 2vw 0;
        .icons{
          img{
            height: 2em;
            max-height: 40px;
          }
        }
      }
    }
    .details-text{width: 86%;}
  }
`